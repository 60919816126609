import React, { useState, useCallback, lazy, Suspense } from 'react'
import { BlogsWithPagination } from '@ashleynexvelsolutions/blogs-with-pagination'
import { InteriorHero } from '@ashleynexvelsolutions/interior-hero'
import { Seo2 } from '@ashleynexvelsolutions/seo2'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import {
  useSeoData,
  PageSpecificSchemaFunction,
  ModalFunction,
  InteriorHeroFunction,
  BlogsWithPaginationFunction,
} from '../DataImports'

const PageSpecificSchema = lazy(() =>
  import('@ashleynexvelsolutions/page-specific-schema').then(module => ({
    default: module.PageSpecificSchema,
  })),
)
const Modal = lazy(() =>
  import('@ashleynexvelsolutions/modal').then(module => ({
    default: module.Modal,
  })),
)

// eslint-disable-next-line no-unused-vars
const BlogIndex = ({ data, pageContext: { totalPages, currentPage } }) => {
  const [formModal, setFormModal] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const clickFunction = useCallback(() => setFormModal(true), [])
  const clickFunction2 = useCallback(() => setFormModal(false), [])

  return (
    <Layout>
      <InteriorHero {...InteriorHeroFunction(data)} onClick={clickFunction} />
      <BlogsWithPagination {...BlogsWithPaginationFunction(data)} />
      <Suspense fallback="loading">
        <Modal {...ModalFunction().modal} isDisplayed={formModal} notDisplayed={clickFunction2} />
      </Suspense>
      <Suspense fallback="loading">
        <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      </Suspense>
    </Layout>
  )
}

export const pageQuery = graphql`
  query WordPressPostArchive {
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        excerpt
        link
        date(formatString: "MMM DD, YYYY")
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        featuredImageAltText {
          featuredImageAltText
        }
      }
    }
    sitePage(path: { eq: "/blog/" }) {
      path
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    wp {
      allSettings {
        readingSettingsPostsPerPage
      }
    }
  }
`
export default BlogIndex
export const Head = ({ data }) => <Seo2 {...useSeoData(data)} />
